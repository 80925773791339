@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: var(--spacing-core-6);

    td {
        padding: 0;
        padding-bottom: var(--spacing-core-2);

        &:first-child {
            display: flex;     
            width: 32px;       
        }

        &:nth-child(2) {
            vertical-align: top;
            width: auto;

            > * {
                margin-left: auto;
                width: max-content;
            }
        }

        &:nth-child(3) {
            width: auto;
        }
    }
}

.checkbox {
    display: inline-block;
    height: var(--sizing-core-4);
    margin-top: var(--spacing-core-1);
    border: none;
    padding: 0;
    background: inherit;
}

.image {
    width: var(--sizing-core-4);
    height: var(--sizing-core-4);
    cursor: pointer;
    outline: none;
}

.unit {
    text-align: right;
}

.name {
    margin-left: var(--spacing-core-4);
}

@include mq.mq($from: medium) {
    .ingredients {
        padding: var(--spacing-core-8) var(--spacing-core-12);
    }
}


